<template>
  <div class="pushconfig-index">
    <div class="container-header">
      <div class="top-actions-group">
        <div class="action-left">
          <el-page-header @back="$router.go(-1)" content="绑定推送设备">
          </el-page-header>
        </div>
        <div class="action-right">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            size="medium"
            @click="onAdd"
            >新增</el-button
          >
        </div>
      </div>
    </div>

    <el-table
      class="list-table"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      style="width: 100%"
      :stripe="true"
      :border="false"
      v-loading="tableLoading"
    >
      <el-table-column label="设备名称" prop="DeviceName"></el-table-column>
      <el-table-column label="设备编号" prop="DeviceCode"></el-table-column>
      <el-table-column label="推送ID" prop="PushId"></el-table-column>
      <el-table-column label="创建时间" prop="CreateTime" width="170">
        <template slot-scope="scope">
          {{ scope.row.CreateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="EnabledMark">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.EnabledMark==0" effect="dark" size="small"
            >启用</el-tag
          >
          <el-tag
            v-else
            type="danger"
            effect="dark"
            size="small"
            >禁用</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            type="success"
            icon="el-icon-edit"
            size="mini"
            @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-button
            type="warning"
            icon="el-icon-error"
            size="mini"
            style="margin-left: 10px"
            @click="onListDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      layout="prev, pager, next"
      prev-text="上一页"
      next-text="下一页"
      class="list-pagination"
      :page-size="page.pagesize"
      hide-on-single-page
      :total="page.total"
      :current-page.sync="page.pageindex"
      @current-change="onPageChange"
    >
    </el-pagination>

    <el-dialog
      :title="formtype == 'add' ? '添加设备' : '修改设备'"
      :visible.sync="dialogFormVisible"
      width="550px"
      custom-class="geology-dialog"
      :modal-append-to-body="true"
      :destroy-on-close="true"
      :before-close="onFormClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="form"
        class="geology-form"
        :rules="formRules"
        :model="form"
        label-width="120px"
        style="padding-top: 20px"
      >
        <el-row>
          <el-col :span="21" :offset="1" v-if="formtype=='add'">
            <el-form-item label="设备" prop="DeviceId">
              <el-select
                v-model="form.DeviceId"
                filterable
                remote
                reserve-keyword
                default-first-option
                clearable
                placeholder="请选择监测设备"
                size="medium"
                :remote-method="getDeviceList"
                :loading="getDeviceLoading"
                @change="onSelectDevice"
              >
                <el-option
                  v-for="item in deviceSelect"
                  :key="item.Id"
                  :label="item.DeviceName"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="21" :offset="1" v-if="formtype=='edit'">
            <el-form-item label="设备名称" prop="DeviceName">
              <el-input
                v-model="form.DeviceName"
                readonly=""
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="21" :offset="1">
            <el-form-item label="设备编码" prop="DeviceCode">
              <el-input
                v-model="form.DeviceCode"
                readonly=""
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="1">
            <el-form-item label="是否启用" prop="EnabledMark">
              <el-switch
                v-model="form.EnabledMark"
                :active-value="0"
                :inactive-value="1"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23" :offset="1">
            <el-form-item>
              <el-button type="primary" size="medium" @click="onSubmit"
                >立即保存</el-button
              >
              <el-button
                type="primary"
                plain
                size="medium"
                style="margin-left: 10px"
                @click="dialogFormVisible = false"
                >取消</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  getDeviceList,
} from "@/api/device";
import {
  getPushDeviceList,
  delPushDevice,
  addPushDevice,
  editPushDevice,
} from "@/api/api";
export default {
  data() {
    return {
      listData: [],
      tableLoading: false,
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },
      dialogFormVisible: false,
      
      getDeviceLoading: false,
      deviceSelect: [],

      formtype: "add",
      form: {
        PushId: 0,
        DeviceId: "",
        EnabledMark: 0,
        DeviceName: "",
        DeviceCode: "",
        Id: 0,
      },
      formRules: {
        DeviceId: [{ required: true, trigger: "blur", message: "请选择设备" }],
      },
    };
  },
  props: {
    detailid: {
      type: [Number, String],
    },
  },
  components: {},
  methods: {
    getDeviceList(value) {
      this.getDeviceLoading = true;
      getDeviceList(1, 10, {
        fname: value,
      }).then((resdata) => {
        this.getDeviceLoading = false;
        if (resdata.code == 0) {
          this.deviceSelect = resdata.data.data;
        }
      });
    },
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;
      getPushDeviceList(this.detailid,this.page.pageindex)
        .then((resdata) => {
          this.tableLoading = false;
          if (resdata.code == 0) {
            this.listData = resdata.data.data;
            this.page.total = resdata.data.count * 1;
          } else {
            this.$message({
              type: "error",
              message: resdata.msg,
            });
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    onListDel(item) {
      this.$confirm("确定要删除选择的数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delPushDevice(item.Id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("删除成功!");
                this.getListData();
              } else {
                this.$message.error("删除失败!" + res.msg);
              }
            })
            .catch((errmsg) => {
              this.$message.error(errmsg);
            });
        })
        .catch(() => {});
    },
    onAdd() {
      this.form = {
        PushId: this.detailid,
        DeviceId: '',
        EnabledMark: 0,
        DeviceName: "",
        DeviceCode: "",
        Id: 0,
      };
      this.formtype = "add";
      this.dialogFormVisible = true;
    },
    onEdit(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.formtype = "edit";
      this.dialogFormVisible = true;
    },
    onSelectDevice(deviceid){
      var device;
      for (let index = 0; index < this.deviceSelect.length; index++) {
        const element = this.deviceSelect[index];
        if(element.Id == deviceid){
          device = element;
          break;
        }
      }
      this.form.DeviceId = device.Id;
      this.form.DeviceCode = device.DeviceCode;
      this.form.DeviceName = device.DeviceName;
    },
    onFormClose() {
      this.getListData();
      this.formHeadIcon = [];
      this.dialogFormVisible = false;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.PushId = this.detailid;
          if (this.formtype != "edit") {
            addPushDevice(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.onFormClose();
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          } else {
            editPushDevice(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  this.onFormClose();
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          }
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.getListData();
    this.getDeviceList();
  },
};
</script>